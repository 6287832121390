<template>
    <!-- Begin Page Content -->
    <div class="container-fluid">

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">SIMULATION: <strong>{{ $root.contests.current.name }}</strong></h1>
        </div>

        <!-- Content Row -->
        <div class="row border-top pt-4">
            <div class="col-12 col-md-6">
                <div class="mb-3">
                    <label for="contestName" class="form-label">Winners Count <strong>{{ winnersCount }}</strong></label>
                    <input type="range" class="form-control" id="winnersCount" min="1" max="30" v-model="winnersCount" placeholder="winnersCount">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="mb-3 pl-5 pt-4">
                    <button class="btn btn-primary" v-on:click="doRun()">Run Simulation</button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table class="table table-hover">
                    <thead class="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Last</th>
                        <th scope="col">First</th>
                        <th scope="col">Email</th>
                        <th scope="col">Created</th>
                        <th scope="col">Valid</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="part in $root.simulation.winners" :key="'part-' + part.id">
                            <th scope="row">{{ part.id }}</th>
                            <td>{{ part.name }}</td>
                            <td>{{ part.surname }}</td>
                            <td>{{ part.email }}</td>
                            <td>{{ part.createdAt.substring(0,10) }}</td>
                            <td><span v-if="part.valid"><i class="fa fa-check" aria-hidden="true"></i></span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>        

    </div>  <!-- container -->

</template>

<script>
// DatePicker https://github.com/mengxiong10/vue2-datepicker#readme
//import DatePicker from 'vue2-datepicker';
//import 'vue2-datepicker/index.css';
//import 'vue2-datepicker/locale/it';

export default {
    name: 'ResultsSimulationPage',
    data() {
        return {
            winnersCount: 3
        }
    },
    components:{
        //DatePicker
    },
    methods: {
        doRun() {
            if(this.winnersCount < 1 || this.winnersCount > 30) {
                this.$root.$refs.dashBoard.showAlert('Wrong winners number');
            }
            this.$root.simulationStart(this.winnersCount);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>