<template>
    <!-- Begin Page Content -->
    <div class="container-fluid">

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">Participants</h1>
            <!-- <a href="#" v-on:click.stop="$root.participantGenerateReport()" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                    class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> -->
        </div>

        <!-- Content Row -->
        <div class="row">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-primary shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    Total</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ $root.stats.all }}</div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-users fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-success shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    Valid</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ $root.stats.valid }}</div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-user-check fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-info shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Valid %
                                </div>
                                <div class="row no-gutters align-items-center">
                                    <div class="col-auto">
                                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ $root.stats.percentage }}%</div>
                                    </div>
                                    <div class="col">
                                        <div class="progress progress-sm mr-2">
                                            <div class="progress-bar bg-info" role="progressbar"
                                                :style="{ 'width':  $root.stats.percentage+ '%' }" :aria-valuenow="$root.stats.percentage" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pending Requests Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                    Today</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ $root.stats.today }}</div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-clock fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mb-4">
                <div class="card shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col-12 col-md-4">
                                <label class="label mr-2">Start Date</label>
                                <DatePicker v-model="$root.exportStartDate" format="YYYY-MM-DD"/>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="label mr-2">End Date</label>
                                <DatePicker v-model="$root.exportEndDate" format="YYYY-MM-DD"/>
                            </div>
                            <div class="col-12 col-md-4 text-center">
                                <a href="#" v-on:click.stop="$root.participantGenerateReport()" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                    class="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table class="table table-hover">
                    <thead class="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Last</th>
                        <th scope="col">First</th>
                        <th scope="col">Email</th>
                        <th scope="col">Created</th>
                        <th scope="col">Valid</th>
                        <th scope="col">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="part,pidx in $root.participants.list" :key="'part-' + part.id">
                            <th scope="row">{{ part.id }}</th>
                            <td>{{ part.name }}</td>
                            <td>{{ part.surname }}</td>
                            <td>{{ part.email }}</td>
                            <td>{{ part.createdAt.substring(0,10) }}</td>
                            <td><span v-if="part.valid"><i class="fa fa-check" aria-hidden="true"></i></span></td>
                            <td>
                                <a href="#" v-on:click.stop="$root.participantShowFields(pidx)"><i class="fas fa-search"></i></a>&nbsp;&nbsp;
                                <a href="#" v-on:click.stop="$root.participantInvalidate(pidx)"><i class="fas fa-trash-alt"></i></a>&nbsp;&nbsp;
                                <a :href="'http://lonesockdev.dev/participants/doc?partId=' + part.id + '&token=' + $root.userGetToken() " target="_doc"><i class="fa fa-file" aria-hidden="true"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="$root.curPart != null" id="detailsoverlay">
            <div id="detailscontainer">
                <div id="closedetails"><a href="#" v-on:click.stop="$root.curPart = null">x</a></div>
                <table class="table table-hover">
                    <thead class="thead-dark">
                        <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="pfield,pfidx in $root.curPart.pfields" :key="'partf-' + pfidx">
                            <td>
                                {{ pfield.fieldName}}
                            </td>
                            <td>
                                {{ pfield.fieldValue}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>  <!-- container -->

</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: 'ParticipantsPage',
    data() {
        return {
            startDate: null,
            endDate: null
        }
    },
    components: { DatePicker },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

#detailsoverlay {
    display:block;
    z-index:4000;
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.5);
    padding-top:20px;
}

#detailscontainer {
    width:80%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 10px;
    padding-left:20px;
    padding-right:20px;
}

#closedetails {
    height:30px;
    line-height: 30px;
    font-weight: bold;
    font-size: 25px;
    background-color: white;
    color:black;
    text-align: right;
    border-radius: 20px 20px 0px 0px;
}

</style>