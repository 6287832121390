'use strict'
//import axios from 'axios';
//import config from '../../lscontest.config.js';
//import moment from 'moment';

export default  {
    data() {
        return {            
            simulation: {
                winners: []
            }
        }
    },
    methods: {
        simulationStart(_winnersCount) {
            if(this.participants.list.length == 0) {
                this.participantGetAll()
                .then( () => {
                    this.simulationRun(_winnersCount);
                })
                .catch( () => {
                    this.$root.$refs.dashBoard.showAlert('Unable to run simulation.','OK');
                })
            } else {
                this.simulationRun(_winnersCount);
            }
        },

        simulationRun(_winnersCount) {
            this.simulation.winners = [];
            let max = this.participants.list.length;
            let tries = 0;
            while(tries < (_winnersCount * 50) && this.simulation.winners.length < _winnersCount) {
                let idx = Math.floor( Math.random() * max);
                if(this.participants.list[idx].valid == 1) {
                    if( !this._winnerExists(this.participants.list[idx]) ) {
                        this.simulation.winners.push( this.participants.list[idx]);
                    }    
                }
                tries++;
            }

            if(tries >= (_winnersCount * 50)) {
                this.$root.$refs.dashBoard.showAlert('<strong>Cannot retrieve all winners.</strong> <br>Winners expected ' + _winnersCount + ' winners found ' + this.simulation.winners.length + '<br>Check for valid participants or count.','OK');
            }
        },

        _winnerExists(part) {
            let found = false;
            this.simulation.winners.forEach( (win) => {
                if(win.id == part.id) {
                    found = true;
                }
            });

            return found;
        }
    }    
}