<template>
    <div id="wrapper">

		<div class="ls-overlay ls-spinner-overlay" v-on:click.stop="waitingClick" :class="{ 'ls-overlay-hidden' : (waiting.visible == false), 'ls-overlay-visible' : (waiting.visible == true)}">
			<div class="ls-spinner">
				<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>		
				<p>{{ waiting.message }}</p>
			</div>
		</div>

		<div class="ls-overlay ls-alert-overlay" :class="{ 'ls-overlay-hidden' : (alert.visible == false), 'ls-overlay-visible' : (alert.visible == true)}">
			<div class="ls-alert bg-primary">
				<div class="row p-3">
					<div class="col-12">
						<p v-html="alert.message"></p>
					</div>
				</div>
				<div class="ls-alert-buttons">
					<div class="alert-btn-left">
						<button v-if="alert.cancelText != ''" v-on:click.stop="_alertCancelPressed" class="btn btn-danger btn-icon-split" >
							<span class="icon text-white-50">
								<i class="fas fa-check"></i>
							</span>
							<span style="min-width:100px;" class="text">{{ alert.cancelText }}</span>
						</button>						
					</div>
					<div class="alert-btn-right text-right">
						<button v-on:click.stop="_alertOkPressed" class="btn btn-success btn-icon-split"  >
							<span class="icon text-white-50">
								<i class="fas fa-check"></i>
							</span>
							<span class="text" style="min-width:100px;">{{ alert.okText }}</span>
						</button>						
					</div>
				</div>
			</div>
		</div>
        
        <LoginPage v-if="$root.user.loggedIn == false"/>
        <SideBar v-if="$root.user.loggedIn"/>
        <!-- Content Wrapper -->
        <div v-if="$root.user.loggedIn" id="content-wrapper" class="d-flex flex-column">

            <!-- Main Content -->
            <div id="content">

                <TopBar/>

				<OverviewPage v-if="curPage == 'overviewpage'"/>
				<ParticipantsPage v-if="curPage == 'participantspage'"/>
				<ContestSettingsPage v-if="curPage == 'contestsettingspage'"/>
				<ContestFieldsPage v-if="curPage == 'contestfieldspage'"/>
				<ResultsSimulationPage v-if="curPage == 'resultssimulationpage'"/>

            </div>
        </div>
    </div>
  
</template>

<script>
import SideBar from './SideBar.vue';
import TopBar from './TopBar.vue';
import LoginPage from './LoginPage.vue';
import OverviewPage from './pages/OverviewPage.vue';
import ParticipantsPage from './pages/ParticipantsPage.vue';
import ContestSettingsPage from './pages/ContestSettings.vue'
import ContestFieldsPage from './pages/ContestFieldsPage.vue'
import ResultsSimulationPage from './pages/ResultsSimulationPage.vue';

export default {
    name: 'DashBoard',
    data() {
        return {
			alert: {
				visible: false,
				message: '',
				okText: 'OK',
				cancelText: 'Annulla',
				okCallback: function() {} ,
				cancelCallback:  function() {}
			},      
			waiting: {
				visible: false,
				message: '',
                locked: false
			},
            curPage: 'overviewpage',
			needSave: false
        }
    },
    components: {
        SideBar,
        TopBar,
        LoginPage,
		OverviewPage,
		ParticipantsPage,
		ContestSettingsPage,
		ContestFieldsPage,
		ResultsSimulationPage
    },
    methods: {

        showPage(_newPage) {
			if(this.needSave) {
				this.showAlert('You have unsaved data. Really want to discard it?','YES','NO')
				.then( () => {
					this.needSave = false;
					this.curPage = _newPage;
				})
				.catch( () => {
					return false;
				})
			} else {
				this.curPage = _newPage;
			}
        },

		setNeedSave(_needSave) {
			this.needSave = _needSave;
		},

		showAlert(_message, _okBtnText, _cancelBtnText) {
			if(_okBtnText == undefined)
				_okBtnText = 'OK';

			if(_cancelBtnText == undefined)
				_cancelBtnText = '';
				
			this.hideWaiting();

			return new Promise( (resolve,reject) => {
				this.alert.message = _message;
				this.alert.okText = _okBtnText;
				this.alert.cancelText = _cancelBtnText;
				this.alert.okCallback = resolve;
				this.alert.cancelCallback = reject;
				this.alert.visible = true;
			});
		},

		_alertOkPressed(_resolveEvent) {
			_resolveEvent.preventDefault();
			this.alert.visible = false;
			this.alert.okCallback('ok premuto');
		},

		_alertCancelPressed(_rejectEvent) {
			console.log(_rejectEvent);
			_rejectEvent.preventDefault();
			this.alert.visible = false;
			this.alert.cancelCallback('cancel premuto');
		},

		showWaiting(_message, _locked = false) {
			if(_message == undefined) {
				_message = '';
			} else {
				this.waiting.message = _message.toUpperCase();
			}
            this.waiting.locked = _locked;
			this.waiting.visible = true;
		},

		hideWaiting() {		
			//waiting is locked by someone who needs it visible
			if(this.waiting.locked)
				return;

			setTimeout( () => {
				this.waiting.visible = false;
                this.waiting.locked = false;
			}, 1000);
		},

		waitingClick(event) {
			event.preventDefault();
		}

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ls-overlay {
	position:fixed;
	top:0px;
	left:0px;
	bottom:0px;
	right:0px;
	display:block;
	width:100%;
	height:100vh;
	margin:0px;
	padding:0px;  

	opacity:0;
	z-index:200;

	overflow-y:auto;
	overflow-x:hidden;

	animation-duration: 0.3s;
	animation-direction: normal;
	animation-iteration-count: 1;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}

@media all and (min-width: 1200px) {
	.ls-overlay {
		padding-left:300px;
		padding-right:300px;
	}
}


.ls-overlay-dark {
	background-color:#000;
}

.ls-overlay-light {
	background-color: #fff;
}

.ls-overlay-visible {
	animation-name: overlayEnter; 
}

.ls-overlay-hidden {
	animation-name: overlayExit;
}

@keyframes overlayExit {
	from {
		transform: translateY(0%);		
		opacity:1;
	}
	40% {
		transform: translateY(15%);
		opacity:1;
	}
	to {
		transform: translateY(-100%);
		opacity:0;
	}
}

@keyframes overlayEnter {
	from {
		transform: translateY(-100%);
		opacity:0;
	}
	to {
		transform: translateY(0%);
		opacity:1;
	}
}


.ls-modal {
	display:block;
	position:relative;
	width:100%;
	height: 400px;
	padding:0px;
	margin:0px;
	margin-top:50vh;
	transform: translateY(-200px);
	background-color: #AC8A65;
	color: #ffffff;
}

.ls-alert-overlay {
	background-color: rgba(0,0,0,0.5);
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	z-index:300;
}

.ls-alert {
	display:block;
	position:relative;
	width:100%;
	min-height: 200px;
	padding:0px;
	padding-bottom: 50px;
	margin:0px;
	margin-top:50vh;
	font-size:1.1rem;
	transform: translateY(-50%);
	/* background-color: #666666; */
	color: #ffffff;
}

.ls-alert-buttons {
	position:absolute;
	display:block;
	bottom:5px;
	left:0px;
	width:100%;
}

.alert-btn-left {
	display: inline-block;
	width:50%;
	padding:10px;	
}

.alert-btn-right {
	display: inline-block;
	width:50%;
	padding:10px;
}

.alert-btn-right:hover {
	color: #fff !important;
}


.ls-spinner-overlay {
	background-color: rgba(0,0,0,0.5);
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	text-align: center;
	z-index:4001;
}

.ls-spinner {
	display:block;
	position:relative;
	width:100%;
	min-height: 200px;
	padding:0px;
	margin:0px;
	margin-top:50vh;
	font-size:1.1rem;
	transform: translateY(-50%);
	background-color: transparent;
	color: #ffffff;
}


/********************************************************************************************************
SPINNER
*********************************************************************************************************/

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
</style>
