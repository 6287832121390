import Vue from 'vue'
import DashBoard from './components/DashBoard.vue'
import './registerServiceWorker'

Vue.config.productionTip = false

import User from './js/UserMixin'
import Contest from './js/ContestMixin'
import Storage from './js/StorageMixin'
import Participants from './js/ParticipantsMixin'
import Stats from './js/StatsMixin'
import Simulation from './js/SimulationMixin'

new Vue({
    el: '#globalvueapp',
    name: 'GlobalApp',
    mixins:[
        User,
        Contest,
        Storage,
        Participants,
        Stats,
        Simulation
    ] ,
    components: {
        DashBoard
    },
    template: '<DashBoard ref="dashBoard"/>',
    data() {
        return {

        }
    },
    created() {
        //try to get the token from local storage
        this.userCheckLocalStorageLogin();
    },
    methods: {
        sampleMethod() {

        }
    }

});
