module.exports = {
    mode: 'dev',  //prod
    serverUrl: 'https://lonesockdev.dev',
    //serverUrl: 'http://localhost:3000',
    version: {
        major: 2,
        minor: 1,
        build: 59
    }
}

