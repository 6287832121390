import axios from 'axios';
import config from '../../lscontest.config.js';
        
export default  {
    data() {
        return {
            stats: {
                all: 0,
                valid: 0,
                today: 0,
                percentage: 100
            }
        }
    },
    methods: {
        statsGetAll() {
            return new Promise( (resolve, reject) => {
                var payload = {
                    method: 'POST',
                    url: config.serverUrl + '/stats/all',
                    headers: { 
                      'Content-Type': 'application/json',
                      'Authorization' : 'Bearer ' + this.userGetToken()
                    }
                };            
                axios(payload)
                .then( (stRes) => {
                    if(stRes.data.error == true) {
                        console.log('Error retrieveing stats');
                    } else {
                        console.log('Retrieved stats');
                        this.stats.all = stRes.data.stats.all;
                        this.stats.valid = stRes.data.stats.valid;
                        this.stats.today = stRes.data.stats.today;
                        this.stats.percentage = 100 * (Math.round( (this.stats.all / this.stats.valid) * 100) / 100); 
                    }
                    resolve(true);
                })
                .catch( (statsErr) => {
                    console.log('Catch stats');
                    console.log(statsErr);
                    this.$root.$refs.dashBoard.showAlert('Unable to retrieve user stats','OK','');
                    reject(false);
                });
    
            });
        }
    }
}