export default  {
    data() {
        return {
        }
    },
    methods: {
        storageGet(key) {
            return localStorage.getItem(key)
        },

        storageSet(key,value) {
            localStorage.setItem(key, value);
        },

        storageRemove(key) {
            localStorage.removeItem(key);
        },

        storageClear() {
            localStorage.clear();
        }

    }
}