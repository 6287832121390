<template>
    <!-- Begin Page Content -->
    <div class="container-fluid">

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">SETTINGS: <strong>{{ $root.contests.current.name }}</strong></h1>
        </div>

        <!-- Content Row -->
        <div class="row border-top pt-4">
            <div class="col-12 col-md-6">
                <div class="mb-3">
                    <label for="contestName" class="form-label">Contest Name</label>
                    <input type="email" class="form-control" id="contestName" v-model="$root.contests.current.name" placeholder="name@example.com">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="mb-3">
                    <label for="contestSlug" class="form-label">Contest Slug</label>
                    <input type="email" class="form-control" id="contestSlug" v-model="$root.contests.current.slug" placeholder="name@example.com">
                </div>
            </div>
            
            <div class="col-12">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" readonly placeholder="Contest api key" aria-label="Contest api key" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"><i class="fas fa-redo"></i></span>
                    </div>
                </div>                
            </div>

            <div class="col-12">
                <div class="form-group">
                    <label for="contestdescription">Description</label>
                    <textarea class="form-control" id="contestdescription" v-model="$root.contests.current.description" rows="3"></textarea>
                </div>
            </div>

            <div class="col-6">
                    <label for="startdate">Start Date</label><br>
                    <date-picker id="startdate" v-model="$root.contests.current.startDate" valueType="format"></date-picker>
            </div>
            <div class="col-6">
                    <label for="enddate">End Date</label><br>
                    <date-picker id="enddate" v-model="$root.contests.current.endDate" valueType="format"></date-picker>
            </div>

            <div class="col-12 text-right">
                <a href="#" class="btn btn-success btn-icon-split">
                    <span class="icon text-white-50">
                        <i class="fas fa-check"></i>
                    </span>
                    <span class="text">Save Contest</span>
                </a>                
            </div>
        </div>

    </div>  <!-- container -->

</template>

<script>
// DatePicker https://github.com/mengxiong10/vue2-datepicker#readme
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/it';

export default {
    name: 'ContestSettingsPage',
    data() {
        return {
        }
    },
    components:{
        DatePicker
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>