import axios from 'axios';
import config from '../../lscontest.config.js';

export default  {
    data() {
        return {
            user: {
                loggedIn: false,
                token: '',
                name: '',
                surname: '',
                email: '',
                remember: false
            }
        }
    },
    methods: {

        userGetToken() {
            return this.user.token;
        },

        doLogout() {
            this.$root.$refs.dashBoard.hideWaiting();
            this.user.loggedIn = false;
            this.user.token = '';
            this.user.name = '';
            this.user.surname = '';
            this.user.email = '';
            this.user.remember = false;            
            this.storageClear();
        },

        doLogin(_email,_password) {
            this.$root.$refs.dashBoard.showWaiting();

            var payload = {
                method: 'post',
                url: config.serverUrl + '/users/login',
                headers: { 
                  'Content-Type': 'application/json'
                },
                data : {
                    email: _email,
                    password: _password
                }
            };            
            axios(payload)
            .then( (loginRes) => {
                if(loginRes.data.error == true) {
                    this.$root.$refs.dashBoard.showAlert('Unable to login','OK','');
                    this.doLogout();
                } else {
                    this.user.token = loginRes.data.token;
                    if(this.user.remember) {
                        this.storageSet('remto', this.user.token);
                    }
                    this.user.loggedIn = true;
                    this.$root.$refs.dashBoard.hideWaiting();
                    console.log('getting me');
                    this.getMe();

                }
            })
            .catch( (loginErr) => {
                console.log(loginErr);
                this.$root.$refs.dashBoard.showAlert('Unable to login','OK','');
                this.doLogout();
            });
        },

        userCheckLocalStorageLogin() {
            let tmpToken = this.storageGet('remto');
            if(tmpToken) {
                this.user.token = tmpToken;
                this.user.loggedIn = true;
                this.getMe();
            }
        },

        getMe() {
            var payload = {
                method: 'GET',
                url: config.serverUrl + '/users/me',
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization' : 'Bearer ' + this.userGetToken()
                }
            };            
            axios(payload)
            .then( (meRes) => {
                if(meRes.data.error == true) {
                    console.log('Error true');
                    this.doLogout();
                } else {
                    console.log('Retrieving contest');
                    this.user.name = meRes.data.first_name;
                    this.user.surname = meRes.data.last_name;
                    this.user.email = meRes.data.email;
                    //get contest data
                    this.contestsGetList();
                    //retrieve stats
                    this.statsGetAll();

                }
            })
            .catch( (loginErr) => {
                console.log('Catch contest');
                console.log(loginErr);
                this.$root.$refs.dashBoard.showAlert('Unable to retrieve user data','OK','');
                this.doLogout();
            });
        }
    }
}