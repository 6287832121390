'use strict'
import axios from 'axios';
import config from '../../lscontest.config.js';
import CSVExporter from './exportToCSV.js';
import moment from 'moment';

export default  {
    data() {
        return {            
            participants: {
                list: []
            },
            curPart: null,
            exportStartDate: null,
            exportEndDate: null
        }
    },
    methods: {
        partsShowMain() {
            this.$root.$refs.dashBoard.showWaiting();
            this.participantGetAll()
            .then( (ok) => {
                console.log(ok);
                this.exportStartDate = null; //new Date(this.$root.contests.current.startDate);
                this.exportEndDate = null; //new Date();
                this.$root.$refs.dashBoard.showPage('participantspage');
            })
            .catch( (err) => {
                console.log(err);
            });
        },

        participantGetAll() {
            return new Promise( (resolve, reject) => {
                this.$root.$refs.dashBoard.showWaiting();

                var payload = {
                    method: 'POST',
                    url: config.serverUrl + '/participants/list',
                    headers: { 
                      'Content-Type': 'application/json',
                      'Authorization' : 'Bearer ' + this.userGetToken()
                    },
                    data: {
                        contestId: this.$root.contests.current.id
                    }
                };            

                axios(payload)
                .then( (partRes) => {
                    this.$root.$refs.dashBoard.hideWaiting();
                    if(partRes.data.error == true) {
                        this.$root.$refs.dashBoard.showAlert('Unable to retrieve participants data','OK','');
                        this.participants.list = [];
                        reject();
                    } else {
                        console.log('Retrieving participants');
                        this.participants.list = partRes.data.participants.reverse();
                        resolve();
                    }
                })
                .catch( (partErr) => {
                    this.participants.list = [];
                    this.$root.$refs.dashBoard.hideWaiting();
                    console.log('Catch contest');
                    console.log(partErr);
                    this.$root.$refs.dashBoard.showAlert('Unable to retrieve participants data','OK','');
                    reject
                });    
            });
        },

        participantShowFields(_pidx) {
            this.curPart = this.participants.list[_pidx];
        },

        participantInvalidate(_pidx) {
            console.log("Invalidate " + _pidx);
            this.$root.$refs.dashBoard.showAlert('Cannot invalidate participant right now.','OK');
        },

        partGetField(pfields, fieldName) {
            let res = null;

            pfields.forEach( (field) => {
                if(field.fieldName == fieldName) {
                    res = field.fieldValue;
                }
            });

            return res;
        },

        participantGenerateReport() {
            let expPart = [];
            let start = moment(this.exportStartDate);
            let end = moment(this.exportEndDate);
            this.participants.list.forEach( (part) => {
                let valid = true;
                let createdAt = moment(part.createdAt);
                if(this.exportStartDate != null) {
                    if(start > createdAt ) {
                        valid = false;
                    }
                }
                if(this.exportEndDate != null) {
                    if(end < createdAt ) {
                        valid = false;
                    }
                }
                if(valid) {
                    expPart.push( {
                        id: part.id,
                        name: part.name,
                        surname: part.surname,
                        email: part.email,
                        mobile: part.mobile,
                        valid: part.valid,                    
                        trattamento: this.partGetField(part.pfields, 'trattamento'),
                        privacy: this.partGetField(part.pfields, 'privacy'),
                        officina: this.partGetField(part.pfields, 'officina'),
                        cod_prenotazione: this.partGetField(part.pfields, 'cod_prenotazione'),
                        importo: this.partGetField(part.pfields, 'importo'),
                        data_fattura: this.partGetField(part.pfields, 'data_fattura'),
                        num_fattura: this.partGetField(part.pfields, 'num_fattura'),
                        file_fattura: this.partGetField(part.pfields, 'file_fattura')
                    });    
                }
            });

            let csvexp = new CSVExporter(expPart);
            let data = moment().format('YYYY-MM-DD');
            csvexp.dwloadCSV(data + '-participants');            
        }
    }
}