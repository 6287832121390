<template>
    <!-- Begin Page Content -->
    <div class="container-fluid">

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="h3 mb-0 text-gray-800">FIELDS: <strong>{{ $root.contests.current.name }}</strong></h1>
        </div>

        <!-- Content Row -->
        <div class="row border-top pt-4">
            <div class="col-12">
                <div v-for="cofield,findex in $root.contests.current.cfields" :key="'cfield-' + findex" class="row mt-3">
                    <div class="col-4">
                        <div class="mb-3">
                            <label :for="'fieldName-' + cofield.fieldName" class="form-label">Field Name</label>
                            <input type="text" class="form-control" :id="'fieldName-' + cofield.fieldName" v-model="cofield.fieldName">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="mb-3">
                            <label :for="'fieldDOMSel-' + cofield.fieldDOMSelector" class="form-label">Field DOM Selector</label>
                            <input type="text" class="form-control" :id="'fieldDOMSel-' + cofield.fieldDOMSelector" v-model="cofield.fieldDOMSelector">
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-check">
                            <input type="checkbox" v-model="cofield.enabled" class="form-check-input checkbox" id="enabledCheck">
                            <label class="form-check-label checklabel" for="enabledCheck">Enabled</label>
                        </div>                        
                    </div>
                    <div class="col-2 pt-4 text-right">
                        <button v-on:click="contestDeleteField(findex)" class="btn btn-danger btn-icon-split mt-2">
                            <span class="icon text-white-50">
                                <i class="fas fa-trash"></i>
                            </span>
                            <span class="text">Delete Field</span>
                        </button>
                    </div>
                    <div class="col-12">
                        <p>
                            <button v-on:click="$root.setCurrentRules(findex)" class="btn btn-primary btn-block btn-sm" type="button" data-toggle="collapse" :data-target="'#cfieldcoll-' + findex" aria-expanded="false" :aria-controls="'cfieldcoll-' + findex">
                                Edit {{ cofield.fieldName }} Rules
                            </button>
                        </p>
                        <div class="collapse" :id="'cfieldcoll-' + findex">
                            <div class="card card-body p-0 m-0">                                
                                <div class="row p-0 m-0">
                                    <div class="col-12 p-0 m-0">
                                        <textarea class="form-control tarea-code" rows="5" v-model="cofield.fieldRules"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row mt-3">
                    <div class="col-12 text-right">
                        <button v-on:click="$root.contestAddField" class="btn btn-info btn-icon-split ml-3">
                            <span class="icon text-white-50">
                                <i class="fas fa-plus"></i>
                            </span>
                            <span class="text">Add Field</span>
                        </button>                                                         
                        <button v-on:click="$root.contestSaveFields" class="btn btn-success btn-icon-split ml-3">
                            <span class="icon text-white-50">
                                <i class="fas fa-check"></i>
                            </span>
                            <span class="text">Save Fields</span>
                        </button>                                                         
                    </div>
                </div>
            </div>
        </div>

    </div>  <!-- container -->

</template>

<script>

export default {
    name: 'ContestFieldsPage',
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tarea-code {
    background-color:#666;
    color:#fff;
    font-family:Courier,monospace;
}

.checkbox {
    height: 25px;
    width: 25px;
    margin-right:10px;
    margin-top:37px;
}

.checklabel {
    padding-left:15px;
    margin-top:37px;
}
</style>