class CSVExporter {

    constructor(_array, _usejson = false, _separator = ';') {
        this.inData = null;
        this.fieldNames = [];
        this.dataRows = [];    
        this.useJSON = _usejson;
        this.separator = _separator;

        if(typeof(_array) == 'object' && Object.prototype.hasOwnProperty.call(_array,'length')) {
            this.inData = _array;
            this.analyzeData(_array);
        } else {
            console.error('CSVExporter: construvtor parameter is not an array');
        }
    }

    analyzeData(_array) {
        if(_array.length > 0) {
            //retrieve the fields names
            let first = _array[0];
            Object.keys(first).forEach( (key) => {
                //let fieldName = key;
                if( typeof(first[key]) != 'object' || this._usejson == true || first[key] == null) {
                    this.fieldNames.push(key);
                }
            });

            //fill the dataRows
            _array.forEach( (row) => {
                let dataRow = [];
                this.fieldNames.forEach( (key) => {
                    if(typeof(row[key]) != 'object') {
                        let str = row[key].toString();
                        str = str.replace(/[\r\n]/gm, '');
                        dataRow.push( str );
                    } else if( row[key] == null) {
                        dataRow.push( 'null' );
                    } else if( this.useJSON) {
                        dataRow.push( JSON.stringify( row[key]) );
                    }
                });
                this.dataRows.push( dataRow );
            });


        } else {
            console.error('CSVExporter: array is 0 length');
        }
    }


    getData() {
        let csvContent = "data:text/csv;charset=utf-8,";


        csvContent += '"' + this.fieldNames.join('","') + '"';
        csvContent += '\r\n';

        this.dataRows.forEach( (row) => {
            let rowStr = '"' + row.join('","') + '"';
            csvContent += rowStr + "\r\n";
        });

        return csvContent;
    }

    dwloadCSV(_fileName) {
        let data = this.getData();
        var a = document.createElement('a');
        a.download = _fileName + '.csv';
        a.href = data;
        a.target = a.download;
        a.click();
    }
}

module.exports = CSVExporter;