import axios from 'axios';
import config from '../../lscontest.config.js';

export default  {
    data() {
        return {
            contests: {
                available: [],
                current: {
                    name: '',
                    slug: '',
                    description: '',
                    cfields: [],
                    startDate: null,
                    endDate: null
                }
            }
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
        resetContests() {
            this.$root.$refs.dashBoard.hideWaiting();
            this.contests.available = [];
            this.contests.current = {
                name: '',
                slug: '',
                description: '',
                startDate: null,
                endDate: null
            //other fields will be loaded at runtime
            };
        },

        contestsGetList() {
            this.$root.$refs.dashBoard.showWaiting();

            var payload = {
                method: 'post',
                url: config.serverUrl + '/contests/list',
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization' : 'Bearer ' + this.userGetToken()
                }
            };            
            axios(payload)
            .then( (contLstRes) => {
                if(contLstRes.data.error == true) {
                    this.$root.$refs.dashBoard.showAler('Unable to retrieve contests','OK','');
                    this.doLogout();
                } else {
                    this.contests.available = contLstRes.data;
                    if(this.contests.available.length > 0) {
                        this.contests.current = this.contests.available[0];
                    }
                    this.$root.$refs.dashBoard.hideWaiting();
                }
            })
            .catch( (contLstErr) => {
                console.log(contLstErr);
                this.$root.$refs.dashBoard.showAlert('Impossibile fare il login','OK','');
                this.doLogout();
            });
        },

        contestAskDeleteField(findex) {
            this.$root.$refs.dashBoard.showAlert('Do you really want to delete this field?','YES','NO')
            .then( () => {
                this.contestAskDeleteField(findex);
            })
            .catch( () => {

            });
        },

        deleteField(findex) {
            this.contests.current.cfields.splice(findex,1);
        },

        contestAddField() {
            this.contests.current.cfields.push({
                ContestId: this.contests.current.id,
                contestId: this.contests.current.id,
                fieldName: 'newfield',
                createdAt: new Date(),
                fieldOrder: this.contests.current.cfields.length + 1,
                fieldRules: '[{ "name" : "required", "value" : true }]"]',
                enabled: 1,
                id: -1,
                contestDOMSelector: '',
                updatedAt: new Date()
            });
        },

        contestSaveFields() {
            this.$root.$refs.dashBoard.showWaiting();

            var payload = {
                method: 'post',
                url: config.serverUrl + '/contests/savefields',
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization' : 'Bearer ' + this.userGetToken()
                },
                data: {
                    fields: this.contests.current.cfields,
                    contestid: this.contests.current.id
                }
            };            
            axios(payload)
            .then( (savefRes) => {
                console.log(savefRes);
                this.$root.$refs.dashBoard.hideWaiting();
            })
            .catch( (savefErr) => {
                console.log(savefErr);
                this.$root.$refs.dashBoard.showAlert('Unable to save contest fields','OK','');
            });           
        }

    }
}